.portfolio-description-container {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    // transform: none !important;
    // -webkit-transform: none !important;
    // opacity: 0;
    &.shown {
        transition: all 1s ease-in;
        opacity: 1;
    }

    .portfolio-description {
        >h2 {
            margin: 0 auto;
            text-align: center;
            max-width: 500px;
        }

        .portfolio-description-clientname {
            font-size: 14px;
            margin: 0 0;
            text-align: center;
        }
    }

    .portfolio-description-text,
    .portfolio-solutions {
        margin: 50px auto;
    }

    .portfolio-results {
        margin-top: 0;

    }
}

.portfolio-description-screenshots {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    // &.portfolio-id-1 {
    //     background-image: url('../../../../resources/video-stills/OSK_01.jpg');
    // }
    // &.portfolio-id-2 {
    //     background-image: url('../../../../resources/video-stills/DOGQ_03.jpg');
    // }
    // &.portfolio-id-3 {
    //     background-image: url('../../../../resources/video-stills/SLEDGE_02.jpg');
    // }
    // &.portfolio-id-4 {
    //     background-image: url('../../../../resources/video-stills/BCMOS_02.jpg');
    // }
    // &.portfolio-id-5 {
    //     background-image: url('../../../../resources/video-stills/POL_03.jpg');
    // }
    // &.portfolio-id-6 {
    //     background-image: url('../../../../resources/video-stills/GOALB_03.jpg');
    // }
    // &.portfolio-id-7 {
    //     background-image: url('../../../../resources/video-stills/NEM_01.jpg');
    // }
    // &.portfolio-id-8 {
    //     background-image: url('../../../../resources/video-stills/RACINNE_03.jpg');
    // }
    .portfolio-description-screenshots-text {
        color: #fff;
        text-align: center;

        h2 {
            font-size: 40px;

            @media only screen and (min-width: $desktop-small) {
                font-size: 50px;
            }

            @media only screen and (min-width: $desktop-large) {
                font-size: 60px;
            }
        }

        p {
            font-size: 20px;

            @media only screen and (min-width: $desktop-small) {
                font-size: 30px;
            }

            @media only screen and (min-width: $desktop-large) {
                font-size: 40px;
            }
        }
    }
}