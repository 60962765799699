@import '../../../variables.scss';

.team-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10%;
    @media only screen and (min-width: $tablet) {
        display: grid;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        max-width: 80%;
        padding: 0;
        margin: 5% auto; 
    }
    @import './member-container.scss';
}
@import './employee-card.scss';
@import './employee-overlay.scss';
