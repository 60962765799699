.homepage-banner {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  transition: background-color 1s ease-in-out, opacity 2s ease-in-out;
}

.homepage-banner-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.homepage-banner-header {
  display: flex;
  flex-direction: column;
}

.homepage-banner-header-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 70%;
  //   @media only screen and (min-width: 768px) {
  //     width: 920px;
  //   }
  max-width: 1000px;
  opacity: 1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  line-height: 1;
  .homepage-banner-header-text,
  .homepage-banner-header__subheader {
    color: #fff;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    > span.homepage-banner-header-highlighted {
      color: #FF5A1E;
      font-weight: 400;
      font-family: Sailec-bold;
    }
  }
  .homepage-banner-header-text {
    margin-bottom: 1rem;
  }
  .homepage-banner-header__subheader {
    font-family: Sailec-Regular;
    font-size: 28px;
    line-height: 1.25;
  }
  .homepage-banner-info {
    position: absolute;
    left: 5%;
    bottom: 5%;
    h3,
    span {
      color: #fff;
      margin: 0;
    }
  }
  .homepage-banner-links {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .homepage-banner .homepage-banner-header-container {
    width: 80%;
    .homepage-banner-header {
      font-size: 50px;
    }
  }
}
@media screen and (max-width: 640px) {
  .homepage-banner .homepage-banner-header-container {
    width: 85%;
    .homepage-banner-header {
      font-size: 45px;
    }
  }
}
@media screen and (max-width: 480px) {
  .homepage-banner .homepage-banner-header-container {
    width: 90%;
    .homepage-banner-header {
      font-size: 35px;
    }
  }
}
