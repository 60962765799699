.side-menu {
	position: absolute;
    top: 200px;
    color: #FF591F;
    font-size: 0.8em;
    font-weight: bold;
    letter-spacing: 1.5px;
	cursor: pointer;
	transition: top 300ms ease-in;
	&.side-menu-prev {
		left: 20px;	
	}
	&.side-menu-next {
		right: 20px;
	}
	&.sticky {
		position: fixed;
		top: 25vh;
		transition: none;
	}
	.side-menu-wrapper {
		.side-line-vertical {
			height: 100px;
			border-left: 1px solid #FF591F;
		}
		.side-menu-text {
			position: absolute;
			top: 140px;
			left: -19px;
			transform: rotate(270deg);
			-webkit-transform: rotate(270deg);
			-moz-transform: rotate(270deg);
			-o-transform: rotate(270deg);
		}
	}
}
