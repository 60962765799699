$grey-overlay: #606060;
$bts-one: url("../../../resources/images/bts-1.jpg") no-repeat center center;
$bts-two: url("../../../resources/images/bts-2.jpg") no-repeat center center;
$bts-three: url("../../../resources/images/bts-3.jpg") no-repeat center center;
$granted: url("../../../resources/images/client-icons/logo-grantedconsulting.png") no-repeat center center;
$kpmg: url("../../../resources/images/client-icons/logo-kpmg.png") no-repeat center center;
$tourism-van: url("../../../resources/images/client-icons/logo-tourismvancouver.png") no-repeat center center;
$vbot: url("../../../resources/images/client-icons/logo-vbot.png") no-repeat center center;
$yvr: url("../../../resources/images/client-icons/logo-yvr.png") no-repeat center center;
$pol: url("../../../resources/images/client-icons/logo-pol-2.jpg") no-repeat center center;
$bcmos: url("../../../resources/images/client-icons/logo-bcmos.jpg") no-repeat center center;
$power: url("../../../resources/images/client-icons/PTB_HorizontalTagline_BW.png") no-repeat center center;
$theme-color: #ff591f;

.modal-open {
  overflow: hidden;
}

.container {
  -webkit-animation: fadein 3s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s;
  /* Firefox < 16 */
  -ms-animation: fadein 3s;
  /* Internet Explorer */
  -o-animation: fadein 3s;
  /* Opera < 12.1 */
  animation: fadein 3s;

  &.with-modal {
    >div:not(.modal-demo-video-container) {
      opacity: 0.5;
    }
  }

  .banner-about {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 300px 0;
    background-size: cover !important;
    z-index: auto;
    -webkit-animation: fadein 3s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s;
    /* Firefox < 16 */
    -ms-animation: fadein 3s;
    /* Internet Explorer */
    -o-animation: fadein 3s;
    /* Opera < 12.1 */
    animation: fadein 3s;
    transition: background-image 750ms ease-in;

    .banner-about-text {
      max-width: 80%;
      text-align: center;
      margin: 0 auto;

      h1 {
        margin: 0 auto;
        font-size: 80px;
        color: #fff;
        line-height: 110%;
        padding: 40px;
        font-family: "Sailec-Bold";

        .wf-theme-accent {
          color: $theme-color;
          line-height: 110%;
          font-size: 50px;
          font-family: "Sailec-Bold";
        }
      }
    }
  }

  .video-reel-container {
    .brand-loyalist {
      position: relative;
      background-color: #fff;
      margin: 0 auto;
      margin-top: -140px;
      max-width: 400px;
      padding: 60px 60px 0;
      border-radius: 1px;
      box-sizing: border-box;

      h3 {
        -webkit-animation: fadein 3s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 3s;
        /* Firefox < 16 */
        -ms-animation: fadein 3s;
        /* Internet Explorer */
        -o-animation: fadein 3s;
        /* Opera < 12.1 */
        animation: fadein 3s;
        color: #000;
        text-align: center;
        margin: 0 auto;
        font-size: 2em;
      }
    }

    .video-reel-header {
      max-width: 700px;
      margin: 0 auto;
      margin-top: 50px;
      text-align: center;
    }

    .modal-demo-video-container {
      max-width: 1000px;

      iframe {
        height: 45vw !important;
        max-height: 550px;
      }
    }

    .video-reel {
      margin: 40px auto 0 auto;
      width: 90%;
      height: 600px;
      background: $bts-three;
      background-size: contain;
      display: flex;
      align-items: center;

      .video-reel-content {
        margin: auto;

        button {
          background: none;
          border: none;
          color: #fff !important;
          font-size: 1.5em;
          font-weight: bolder;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .line-vertical {
      position: relative;
      bottom: 0;
      left: 0;
      transform: none;
      margin: 20px auto;
      height: calc(75vh * 0.15);
    }

    .video-reel-clients-container {
      padding-top: 60px;

      .video-reel-clients-text {
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
      }

      .video-reel-clients {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 75%;
        margin: 40px auto 0;

        >.video-reel-clients-icons {
          margin: 0.25rem 0.25rem;
        }
      }
    }
  }

  .heart-container {
    padding-top: 150px;
    padding-bottom: 0;
    position: relative;

    .heart-content {
      width: 70%;
      margin: 0;

      .heart-content-header {
        margin-bottom: 60px;

        h2 {
          // font-size: 2.6em;
          color: #000;
        }
      }

      .heart-content-text {
        span {
          // color: #C8D8DB;
          color: #000;
        }
      }

      h2,
      p {
        color: #000;
      }
    }
  }

  .demo-images-container {
    position: relative;
    padding: 0 5%;
    margin-top: -240px;

    @media only screen and (min-width: 414px) {
      margin-top: -200px;
    }

    @media only screen and (min-width: 768px) {
      margin-top: -100px;
    }

    >div {
      display: table;
      width: 100%;

      .demo-images {
        background-size: cover !important;
        display: table-cell;
        height: 30vw;

        &:nth-child(1) {
          width: 60%;
        }

        &:nth-child(2) {
          width: 40%;
        }
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .container .banner-about {
    padding: 250px 0;

    .banner-about-text {
      max-width: 700px;

      h1 {
        font-size: 70px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .banner-about {
      padding: 200px 0 250px;

      .banner-about-text {
        max-width: 600px;

        h1 {
          font-size: 55px;

          .wf-underline {
            border-bottom-width: 6px;
          }
        }
      }
    }

    .video-reel-container .video-reel-clients-container .video-reel-clients>.video-reel-clients-icons {
      width: 25%;
      height: 100%;
    }

    .heart-container {
      padding-bottom: 100px;

      .side-nav-container {
        width: 42px;

        .side-nav-wrapper .side-nav {
          left: -38px;
        }
      }

      .heart-content {
        width: 100%;
      }
    }

    .demo-images-container {
      padding: 0;

      >div .demo-images {
        height: 50vw;

        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .banner-about {
      padding: 180px 0 220px;

      .banner-about-text {
        max-width: none;

        h1 {
          font-size: 45px;

          .wf-underline {
            border-bottom-width: 4px;
          }
        }
      }
    }

    .video-reel-container {
      .brand-loyalist h3 {
        font-size: 1.5em;
      }

      .video-reel-clients-container .video-reel-clients>.video-reel-clients-icons {
        width: 25%;
        height: 100%;
      }
    }
  }
}

@import "./demoVideo.scss";