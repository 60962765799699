.footer-contact-container.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    padding: 0;
    height: 75vh;
    position: relative;
    .line-vertical {
        height: calc(75vh * 0.15);
        margin-top: -200px; // Donna feedback for 100vh of container
    }
    .footer-contact {
        .footer-contact-header {
            text-align: center;
            margin: 0 auto;
            max-width: 75%;
            > h3 {
                font-size: 2em;
                font-weight: bold;
                color: #FF5A1E;
            }
        }
        .footer-contact-text {
            text-align: center;
            a {
                color: #333;
                border-bottom: 1px solid #333;
                &:hover {
                    color: #5a5a5a;
                    border-bottom-color: #5a5a5a;
                }
            }
        }
    }
    &.has-section {
        height: 100vh;
        .line-vertical {
            height: calc(75vh * 0.15);
        }
    }
}
@media all and (max-width: 480px){
    .footer-contact-container.wrapper {
        .line-vertical {
            height: 125px;
        }
        .footer-contact .footer-contact-header > h3 {
            font-size: 1.7em;
        }
    }
}