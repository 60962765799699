@import '../../../variables.scss';

.nav-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    // height: 1000px;
    display: flex;
    align-items: center;
    z-index: 10000;
    background-color: #ED6436;
    transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    &.hide {
        opacity: 0 !important;
    }
    .nav-menu-list-container {
        width: 80%;
        height: 80%;
        margin: 0 auto;
        padding-top: 10%;
        ul {
            > li {
                list-style: none;
                margin: 6px;
                a {
                    font-weight: bolder;
                    text-decoration: none;
                    color: #fff;
                    letter-spacing: 1px;
                    padding-bottom: 2px;
                    line-height: 140%;
                    font-size: 30px;
                    @media only screen and (min-width: $tablet) {
                        font-size: 40px;
                    }
                    @media only screen and (min-width: $desktop-small) {
                        font-size: 50px;
                    }
                    @media only screen and (min-width: $desktop-large) {
                        font-size: 60px;
                    }
                }
            }
            a:hover {
                border-bottom: 4px solid #fff;
            }
        }
    }
    .nav-menu-close {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 40px;
        > span {
            font-size: 40px;
            line-height: 40px;
            color: #fff;
        }
    }
}
@media screen and (max-width:480px) {
    .nav-menu .nav-menu-close {
        right: 20px;
    }
}