@charset "UTF-8";
.contact-container {
  background-color: #233539;
  -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s; /* Firefox < 16 */
  -ms-animation: fadein 0.2s; /* Internet Explorer */
  -o-animation: fadein 0.2s; /* Opera < 12.1 */
  animation: fadein 0.2s;
  height: 100vh;
}
.contact-container .contact {
  max-width: 800px;
  color: #fff;
  margin: 0;
}
.contact-container .contact .contact-container-header {
  padding-top: 200px;
}
.contact-container .contact .contact-container-header > h2 {
  margin: 0 0 25px 0;
}
.contact-container .contact .contact-container-header > p {
  margin: 0 0 45px 0;
  line-height: 1.8;
}
.contact-container .contact label {
  color: #9098A9;
  font-weight: 500;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform-origin: 0 0;
  transition: all 0.2s ease;
}

.wf-form-section {
  position: relative;
  margin: 20px auto;
}
.wf-form-section.wf-form-section-inline {
  display: inline-block;
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
}
.wf-form-section.wf-form-section-block {
  margin-top: 25px;
}
.wf-form-section > label {
  position: absolute;
  left: 0;
  color: #9098a9;
  font-weight: 500;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  /*
          &.input-focused {
              font-size: 16px;
              transform: translateY(-15px) scale(.85);
          }
          */
}
.wf-form-section > input,
.wf-form-section textarea {
  background: transparent;
  border: none;
  width: 100%;
  color: #ffffff;
  padding-right: 20px;
  -webkit-appearance: none;
  background: none;
  box-sizing: border-box;
  border-radius: 0;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #fff;
  transition: all 0.15s ease;
}
.wf-form-section > input:focus,
.wf-form-section textarea:focus {
  border: 0;
  outline: none;
}

.wf-contact-submit {
  text-transform: uppercase;
  padding: 10px 15px;
  background: #ff5a1e;
  box-shadow: none;
  border: none;
  color: #fff;
}
.wf-contact-submit::after {
  content: "→";
  margin-left: 3px;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.wf-contact-submit:hover {
  cursor: pointer;
}
.wf-contact-submit:hover::after {
  margin-left: 6px;
}

label {
  letter-spacing: 1px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}