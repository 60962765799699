.circle-nav-container {
	z-index: 10;
	width: 30px;
	height: 100px;
	display: block;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	.circle-nav {
		background: none;
		border: 1px solid #FF591F;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		margin: 5px 0;
		transition: background-color 500ms ease-in;
		&.active {
			background-color: #FF591F;
		}
	}
}
@media screen and (max-width:480px) {
    .circle-nav-container {
        .circle-nav {
            width: 7px;
            height: 7px;
        }
    }
}