.portfolio-next-container {
    overflow: hidden;
    height: 100vh;
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
    // opacity: 0;
    opacity: 1;
    transition: all 1s ease-in;

    &.shown {
        opacity: 1;
    }
}

.portfolio-banner {
    width: 100vw;
    background-color: #233539;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.portfolio-next {
        -webkit-transition: 0.6s ease;
        transition: 0.6s ease;
        cursor: pointer;
        background-color: #fff;

        &.portfolio-id {
            height: 100%;
            width: 100%;
            color: #fff;
            display: flex;
            align-items: center;

            >div {
                margin: 0 auto;

                >h2,
                h3 {
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }

        &:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    // &.portfolio-id-1 {
    //     background-image: url('../../../../resources/video-stills/OSK_01.jpg');
    // }

    // &.portfolio-id-2 {
    //     background-image: url('../../../../resources/video-stills/DOGQ_01.jpg');
    // }

    // &.portfolio-id-3 {
    //     background-image: url('../../../../resources/video-stills/SLEDGE_02.jpg'); // SLEDGE HOCKEY
    // }

    // &.portfolio-id-4 {
    //     background-image: url('../../../../resources/video-stills/BCMOS_01.jpg');
    // }

    // &.portfolio-id-5 {

    //     background-image: url('../../../../resources/video-stills/POL_01.jpg');
    // }

    // &.portfolio-id-6 {
    //     background-image: url('../../../../resources/video-stills/GOALB_03.jpg');
    // }

    // &.portfolio-id-7 {
    //     background-image: url('../../../../resources/video-stills/NEM_01.jpg');
    // }

    // &.portfolio-id-8 {
    //     background-image: url('../../../../resources/video-stills/RACINNE_03.jpg');
    // }
}