.partner-statement-container {
    position: relative;
    height: auto;
    .side-nav-container .side-nav-wrapper .side-nav {
        top: 60px;
    }
    .partner-statement {
        position: relative;
        display: flex;
        padding-top: 200px;
        padding-bottom: 200px;
        justify-content: center;
        align-items: center;
        opacity: 0 !important;
        background-color: #fff;
        transition: opacity 300ms ease-in, background-color 750ms ease-in !important;
        &.reveal {
            background-color: #233539;
            opacity: 1 !important;
        }
    }
    .partner-statement-content {
  
    }
}
/* Media Queries */
@media screen and (max-width:768px) {
    .partner-statement-container {
        .side-nav-container {
            width:45px;
            .side-nav-wrapper .side-nav{
                left: -38px;
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .partner-statement-container .partner-statement{
        padding-top:100px;
        padding-bottom: 125px;
    }
}