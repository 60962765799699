.portfolio-id { // There is space between 2 and 3rd item...why?
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
	padding-bottom: 0;
    height: 50vh;
    @media only screen and (min-width: $tablet) {
        height: 100vh;
    }
    &.portfolio-id-1 {
        background-image: url('../../../../resources/video-stills/OSK_06.jpg');
    }
    &.portfolio-id-2 {
        background-image: url('../../../../resources/video-stills/DOGQ_04.jpg');
    }
    &.portfolio-id-3 {
        background-image: url('../../../../resources/video-stills/SLEDGE_01.jpg'); 
    }
    &.portfolio-id-4 {
        background-image: url('../../../../resources/video-stills/BCMOS_03.png');
    }
    &.portfolio-id-5 {
        background-image: url('../../../../resources/video-stills/POL_06.jpg');
    }
    &.portfolio-id-6 {
        background-image: url('../../../../resources/video-stills/GOALB_01.jpg');
    }
    &.portfolio-id-7 {
        background-image: url('../../../../resources/video-stills/NEM_01.jpg');
    }
    &.portfolio-id-8 {
        background-image: url('../../../../resources/video-stills/RACINNE_03.jpg');
    }
}