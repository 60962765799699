.side-nav-container {
	z-index: 10;
	position: absolute;
	top: -60px;
	left: 0;
	width: 60px;
	height: 0;
	// .side-nav-wrapper {
	// 	position: relative;
	// }
	.line-vertical {
		position: relative;
		margin-top: 30px;
		display: block;
		height: 0;
		top: 0;
		border-left: 1px solid #FF591F;
		// height: 150px;
		transition: height 1s ease-in-out;
	}
	.side-nav-wrapper {
		position: relative;
		.side-nav {
			position: absolute;
			top: 25px;
			left: -30px;
			width: 125px;
			text-transform: uppercase;
			// margin: 30px 0 0 30px;
			margin: 0;
			transform: rotate(270deg);
			-webkit-transform: rotate(270deg);
			color: #FF591F;
			font-size: 0.8em;
			font-weight: bold;
			letter-spacing: 1.5px;
		}
	}
}
@media all and (max-width:480px){
    .side-nav-container .side-nav-wrapper .side-nav {
        font-size: 0.7em;
        top: 25px;
    }
}