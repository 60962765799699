.line-vertical {
	position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    border-radius: 3px;
    background-color: #FF5A1E;
    opacity: 0.6;
    height: calc(75vh * 0.15);
}
	