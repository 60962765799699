.employee-card {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    display: flex;
    opacity: 1;
    &.hide {
        transition: opacity 0.2s ease-in-out;
        opacity: 0 !important;
    }
    .employee-details-container {
        z-index: 200;
        width: 75%;
        position: relative;
        @media only screen and (min-width: $tablet) {
            width: 50%;
        }
        margin: auto auto;
        .employee-details-close {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            font-size: 20px;
            > span {
                color: #fff;
            }
        }
        > .employee-details {
            position: relative;
            box-sizing: border-box;
            display: flex;
            .employee-details-image {
                width: 40%;
                display: none; // Temp solution for tablet and below
                @media only screen and (min-width: $desktop-small) {
                    display: block;
                }
                > img {
                    display: block;
                    box-sizing: border-box;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .employee-details-text-container {
                background-color: #233539;
                text-align: left;
                padding: 20px 20px;
                width: 100%;
                box-sizing: border-box;
                @media only screen and (min-width: $desktop-small) {
                    width: 60%;
                }
                .employee-details-text {
                    display: flex;
                    flex-direction: column;
                    overflow-wrap: break-word;
                    h1, p {
                        color: #fff; 
                        margin: 0; 
                    }
                    h1 {
                        width: 75%;
                        font-size: 1.2rem;
                        @media only screen and (min-width: $tablet) {
                            width: 100%;
                        }
                    }
                    p {
                        font-size: 1rem;
                    }
                    .employee-details-text-title {
                        font-size: 1rem;
                        color: #c7d9dd;
                        @media only screen and (min-width: $tablet) {
                            font-size: 1rem;
                        }
                        @media only screen and (min-width: $desktop-small) {
                            font-size: 1rem;
                        }
                        @media only screen and (min-width: $desktop-large) {
                            font-size: 1rem;
                        }
                    }
                    p:nth-child(2) {
                        margin-top: 5px;
                        @media only screen and (min-width: 1000px) {
                           margin-top: 10px;
                        }
                    }
                    p:last-child {
                        margin-top: 20px;
                    }
                    // Needs refactoring for easier debugging
                    @media only screen and (min-width: 768px) {
                        h1 {
                            font-size: 1.1rem;
                        }
                        p {
                            font-size: 0.8rem;
                        }
                    }
                    @media only screen and (min-width: 1000px) {
                        h1 {
                            font-size: 1.5rem;
                        }
                        p {
                            font-size: 1rem;
                        }
                    }
                    @media only screen and (min-width: 1200px) {
                        h1 {
                            font-size: 2rem;
                        }
                        p {
                            font-size: 1rem;
                        }
                    }
                    @media only screen and (min-width: 1400px) {
                        h1 {
                            font-size: 2.2rem;
                        }
                        p {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}