@import '../../../variables.scss';

.blog-list {
  display: flex;
  flex-wrap: wrap;
  .blog-link {
    transition: 0.3s;
    box-sizing: border-box;
    color: #fff;
    width: 50%;
    height: 50vw;
    display: flex;
    // display: block;
    // float: left;
    border-bottom: unset;
    padding: 0;
    @media only screen and (min-width: $tablet) {
      width: calc(100% / 3);
      height: calc(100vw / 3);
    }
    .blog-thumbnail {
      background-size: cover !important;
      width: 100%;
      height: 100%;
      display: flex;
    }
    .blog-title {
      font-size: 1.2em;
    }
    .blog-date {
      font-size: 0.8em;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #c8d8db;
    }
  }
}