// TO DO: Self-contain styling sheets for each component.

@import "../../../variables.scss";

$background: url("../../../resources/images/homepage-banner-osk.jpg");

h1 {
  line-height: 110%;
}

.homepage {
  > div {
    min-height: 600px;
  }
}

@import "./homepage-banner.scss";
@import "./value-statement.scss";
@import "./partner-statement.scss";

.links-container {
  margin: 20px 20px;
}
.footer-contact-container .sns-container {
  position: absolute;
  bottom: 0;
  width: auto;
  .sns-main {
    text-align: center;
  }
}

.homepage-logo__container {
  display: flex;
}

.homepage-logo {
  width: 125px;
  height: 175px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.b-corp-logo {
  margin-right: 12px;
  @media only screen and (min-width: 768px) {
    margin-right: 24px;
  }
  background-image: url("../images/2018-B-Corp-Logo-White-L.png");
}

.storybrand-guide {
  background-image: url("../images/storybrand_guide.png");
}
