.portfolio-video-container {
    width: 100%;
    height: 300px;
    @media only screen and (min-width: $tablet) {
        height: 100%;
    }
    .modal-demo-video {
        width: 100%;
        height: 100%;
        position: relative;
        @media only screen and (min-width: $tablet) {
            height: 50vh;
        }
    }
}