@import '../../../variables.scss';

.sns-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 0;
    width: auto;
    @media only screen and (min-width: $tablet) {
        flex-direction: row;
        width: 75%;
    }
    .sns-main {
        .follow {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 0.9em;
            display: block;
            margin: 0 auto;
            text-align: center;
        }
        > div {
            margin-top: 10px;
            span, a {
                color: #FF5A1E;
                border-bottom-color: #FF5A1E;
                &:hover {
                    padding-bottom: 7px;
                }
            }
        }
    }
}