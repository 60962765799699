.member-container {
    position: relative;
    cursor: pointer;
    width: 100%;
    margin: 10px 0;
    @media only screen and (min-width: $mobile-large) {
        margin: 20px 0;
    }
    @media only screen and (min-width: $tablet) {
        margin: 0;
    }
    > .member-img {
        img {
            max-width: 100%;
            display: block;
            @media only screen and (max-width: $tablet) {
                width: 100%;
            }
        }
    }
    .member-details-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #233539;
        .member-details {
            padding: 30px 30px;
            .member-name, .member-title {
                color: #fff;
                margin: 0;
            }
            .member-name {
                font-size: 24px;
                line-height: 1.2em;
                @media only screen and (min-width: $mobile-large) {
                    font-size: 30px;
                }
                @media only screen and (min-width: $tablet) {
                    font-size: 24px;
                }
                @media only screen and (min-width: $desktop-small) {
                    font-size: 36px;
                }
                @media only screen and (min-width: $desktop-large) {
                    font-size: 42px;
                }
            }
            .member-title {
                margin-top: 4px;
                font-size: 12px;
                line-height: 1.5em;
                @media only screen and (min-width: $mobile-large) {
                    font-size: 16px;
                }
                @media only screen and (min-width: $tablet) {
                    font-size: 12px;
                }
                @media only screen and (min-width: $desktop-small) {
                    font-size: 18px;
                }
                @media only screen and (min-width: $desktop-large) {
                    font-size: 24px;
                }
            }
            .title-email {
                position: absolute;
                bottom: 0;
                span {
                    margin-left: 5px;
                }
            }
        }
    }
}  
