.portfolio-testimonial {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 140px 0;
    min-height: 300px;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    // opacity: 0;
    &.shown {
        transition: all 1s ease-in;
        opacity: 1;
    }
    > div {
        margin: 0 auto;
        text-align: center;
    }
    .portfolio-testimonial-clientname {
        margin-top: 40px;
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: 1.5px;
        color: #FF5A1E;
        text-transform: uppercase;
    }
}