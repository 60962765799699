@import '../../../../variables.scss';

.portfolio-single-container {
    display: flex;
    flex-wrap: wrap;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

@import './video.scss';
@import './description.scss';
@import './testimonial.scss';
@import './next-container.scss';