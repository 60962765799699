// Need to refactor with the portfolio-id.scss so it's easier to debug. Right now portfolio contaier half is taken care of here, whereas portfolio container id is taken care of in imported stylesheet
@import '../../../../variables.scss';

.portfolios-all {
    display: flex;
    flex-wrap: wrap; 
    .portfolio-container-full {
        width: 100%;
        -webkit-transition: 0.2s ease all; /* Safari, Chrome and Opera > 12.1 */
        -moz-transition: 0.2s ease all; /* Firefox < 16 */
        -ms-transition: 0.2s ease all; /* Internet Explorer */
        -o-transition: 0.2s ease all; /* Opera < 12.1 */
        transition: 0.2s ease all;
    }
    .portfolio-container-half {
        width: 100%;
        // @media only screen and (min-width: $tablet) {
		// 	width: 50%;
		// }
    }
    .portfolio-info {
        margin-bottom: 5%;
        margin-left: 5%;
        align-self: flex-end;
        padding-bottom: 0;
        h3, p {
            color: #fff;
            margin: 0;
        }
        h3 {
            font-weight: 600;
            font-size: 16px;
            @media only screen and (min-width: $tablet) {
                font-size: 18px;
            }
            @media only screen and (min-width: $desktop-small) {
                font-size: 20px;
            }
        }
        p {
            font-size: 12px;
            @media only screen and (min-width: $tablet) {
                font-size: 14px;
            }
            @media only screen and (min-width: $desktop-small) {
                font-size: 18px;
            }
        }
    }
    @import './portfolio-id.scss';
}