// Header with grey solid background
// Portfolio listing, portfolio individual

.header-container {
    width: 100%;
    background: none;
    position: fixed;
    z-index: 100;
    transition: 0.5s ease background-color;
    -webkit-transition: 0.5s ease background-color;
    &.header-portfolio {
        background-color: #fff;
    }
    &.header-container-solid {
        background-color: #fff;
        .header {
            #logo {
                .header-logo {
                    background: url('../images/wf-header-logo-black.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .header {
        // background-color: #D3D3D3;
        background: none;
        position: relative; 
        padding: 0 0;
        margin: 15px 0;
        height: 30px;   
        display: flex;
        #logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            .header-logo {
                background: url('../images/wf-header-logo.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 160px;
                height: 30px;
                -webkit-transition: background-image 0.5s ease-in-out;
                transition: background-image 0.5s ease-in-out;
            }
        }
        .header-menu {
            align-self: center;
            position: absolute;
            right: 40px;
            cursor: pointer;
            &:hover {
                > .header-menu-icon {
                    width: 30px;
                }
            }
            > div {
                &.header-menu-icon {
                    background-color: #fff;
                    width: 24px;
                    height: 3px;
                    margin: 6px 0 6px auto;
                    transition: 0.5s ease all;
                    -webkit-transition: 0.5s ease all;
                    &.header-menu-icon-black {
                        background-color: #000;
                    }
                    &:nth-child(2) {
                        width: 30px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:480px) {
    .header-container .header {
        #logo .header-logo {
            width: 130px;
            height: 25px;
        }
        .header-menu {
            right: 20px;
        }
    }
}