.about-footer {
  padding-top: 20px;
  .line-vertical {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    margin: 20px auto;
    height: calc(75vh * 0.15);
  }
  .about-footer-content {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    h2 {
      color: #ed6436;
    }
    span {
      color: #000;
    }
  }
}