@charset "UTF-8";
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #61dafb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn {
  width: 200px;
  height: 50px;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 20px;
  background: rgb(199, 196, 23);
  border: none;
}
.btn:hover {
  background: rgb(236, 233, 36);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wf-contact-submit {
  text-transform: uppercase;
  padding: 10px 15px;
  background: #ff5a1e;
  box-shadow: none;
  border: none;
  color: #fff;
}
.wf-contact-submit::after {
  content: "→";
  margin-left: 3px;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.wf-contact-submit:hover {
  cursor: pointer;
}
.wf-contact-submit:hover::after {
  margin-left: 6px;
}

label {
  letter-spacing: 1px;
}

input {
  border: none;
  outline: none;
}

.portfolio-id:hover {
  cursor: pointer;
}
.portfolio-id:hover .portfolio-info {
  padding-bottom: 20px;
  transition: 0.2s all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-transition: 0.2s all;
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

@media only screen and (min-width: 768px) {
  .portfolios-all:hover .portfolio-img:not(:hover) {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
  }
  .portfolios-all {
    transition: 0.5s filter ease-in !important;
  }
  .portfolios-all .portfolio-img {
    transition: 0.5s filter ease-in !important;
  }
  .portfolios-all .portfolio-img:hover {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }
}
button {
  font-size: 0.8em;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: Sailec-Regular;
  background: none;
}