.portfolio-id {
  .portfolio-info {
    .wf-portfolio-text-clientname {
      font-size: 24px;
      @media only screen and (min-width: 768px) {
        font-size: 28px;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 32px;
      }
    }
    .wf-portfolio-text-title {
      font-size: 18px;
      @media only screen and (min-width: 768px) {
        font-size: 22px;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 26px;
      }
    }
  }
}