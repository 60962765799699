@import '../../../variables.scss';

.blog-header {
  padding-top: 20px;
  .line-vertical {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    margin: 20px auto;
    height: calc(75vh * 0.15);
  }
  .blog-header-content {
    margin: 0 auto;
    // max-width: 500px;
    text-align: center;
    h2 {
      line-height: 1.2;
      color: #ed6436;
      font-size: 6.5vw;
      @media only screen and (min-width: $tablet) {
        font-size: 50px;
      }
    }
    span {
      color: #000;
    }
  }
  .header-content-text {
    text-align: center;
    span {
      text-align: center;
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 1px;
    }
  }
}
.blog-list {
  display: flex;
  flex-wrap: wrap;
  .blog-link {
    transition: 0.3s;
    box-sizing: border-box;
    color: #fff;
    width: 50%;
    height: 50vw;
    display: flex;
    // display: block;
    // float: left;
    border-bottom: unset;
    padding: 0;
    @media only screen and (min-width: $tablet) {
      width: calc(100% / 3);
      height: calc(100vw / 3);
    }
    .blog-thumbnail {
      background-size: cover !important;
      width: 100%;
      height: 100%;
      display: flex;
    }
    .blog-title {
      font-size: 1.2em;
    }
    .blog-date {
      font-size: 0.8em;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #c8d8db;
    }
  }
}
.portfolio-info {
  margin-bottom: 5%;
  margin-left: 5%;
  align-self: flex-end;
  padding-bottom: 0;
  h3,
  p {
    color: #fff;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    @media only screen and (min-width: $tablet) {
      font-size: 18px;
    }
    @media only screen and (min-width: $desktop-small) {
      font-size: 20px;
    }
  }
  p {
    font-size: 12px;
    @media only screen and (min-width: $tablet) {
      font-size: 14px;
    }
    @media only screen and (min-width: $desktop-small) {
      font-size: 18px;
    }
  }
}

.buttonWrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.search {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  input {
    padding: 7px 35px 7px 15px;
    background: #fafafa;
    border-radius: 15px;
    background: url(../images/icon-search.png) no-repeat center right;
    transition: 0.2s all;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    font-size: 0.8em;
    font-family: 'Sailec' sans-serif;
    width: 0px;
    &:focus,
    &:active {
      outline: 0;
      opacity: 1;
      width: 100%;
      background: #fafafa url(../images/icon-search.png) no-repeat center right;
    }
  }
}

.wf-form-section {
  & > label {
    color: #000;
    font-weight: 700;
  }
}
.subscribe {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 140px;
  .text {
    margin: 0;
    @media only screen and (max-width: $tablet) {
      text-align: center;
    }
  }
  form {
    width: 100%;
    @media only screen and (min-width: $tablet) {
      width: calc(100% - 371.69px);
    }
    .wf-form-section {
      width: calc(100% - 114px);
    }
  }
}
