@import 'custom/style.scss';
.wrapper {
    padding: 100px 10%;
}
.text-container {
    max-width: 800px;
    margin: 0 auto;
}
.container-full {
    display: flex;
}

.container-full-green {
    display: flex;
    background-color: #233539;
}

.container-middle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

.half-width {
    max-width: 50%;
    position: relative;
    &.half-width-left {
        flex: 1;
        background: red;
    }
    &.half-width-right {
        flex: 2;
    }
}

.padding10 {
    padding: 10px 10px;
}

div {
    text-align: left;
}

h1, h2, h3, h4 {
    margin: 20px 20px;
}

h2 {
    font-size: 2em;
}
a, a:active, a:visited {
    color: #333;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}
p {
    margin: 20px 20px;
    // margin: 0;
    font-size: 1.2em;
    line-height: 1.6em;
}
.wf-text-white {
    color: #fff;
}

a.wf-links {
    color: #FF5A1E;
    text-decoration: none;
    text-decoration:none;
    border-bottom: 1px solid transparent;
    padding-bottom: 5px;
    transition: 0.3s ease all;
    &:hover {
        border-bottom: 1px solid #333; 
        border-bottom-color: #FF5A1E;
    }
}

.wf-underline {
    text-decoration: underline;
}

.wf-text-custom {
    color: #C8D8DB !important;
}

/* Media Queries */
@media screen and (max-width: 480px) {
    h2 {
        font-size: 1.5em;
    }
    p {
        font-size: 1em;
    }
}