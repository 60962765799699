.filterBar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  button {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    margin-right: 40px;
    letter-spacing: 1px;
    color: #c7d9dd;
    background: none;
    border: none;
    transition: color 250ms ease-in;
    &.active,
    &:hover {
      color: #000;
    }
  }
}

.wf-filter-input {
  font-family: Sailec-Regular;
}