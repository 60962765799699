.footer-2022__container {
  padding: 40px 40px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 100px 100px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 200px 200px;
  }
}

.footer-2022-right {
  display: flex;
  margin-top: 36px;
  @media only screen and (min-width: 1024px) {
    margin: 0;
  }
}

// This can be reused with .header-logo in Nav
.footer-2022-logo {
  background: url("../images/wf-header-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 62px;
}

.footer-2022-logo-text {
  font-size: 14px;
  color: white;
  margin: 0;
  padding-left: 2px;
}

.footer-2022-link-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  @media only screen and (min-width: 768px) {
    width: 140px;
  }
}

.footer-2022-link-header {
  color: white;
  margin: 0;
  font-weight: 500;
  font-family: "Sailec-bold";
  margin-bottom: 12px;
}

.footer-2022-link-text {
  color: white !important;
  margin: 0;
}
