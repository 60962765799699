@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3668563, 2018-11-08T01:30:10-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed /Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Sailec-Bold by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/bold/
 * 
 * Webfont: Sailec-Regular by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/regular/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3668563
 * Licensed pageviews: 20,000
 * Webfonts copyright: Copyright &#x00A9; 2014 by Type Dynamic. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/37fa53");
@font-face {
  font-family: "Sailec-Bold";
  src: url("/webfonts/37FA53_0_0.eot");
  src: url("/webfonts/37FA53_0_0.eot?#iefix") format("embedded-opentype"), url("/webfonts/37FA53_0_0.woff2") format("woff2"), url("/webfonts/37FA53_0_0.woff") format("woff"), url("/webfonts/37FA53_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "Sailec-Regular";
  src: url("/webfonts/37FA53_1_0.eot");
  src: url("/webfonts/37FA53_1_0.eot?#iefix") format("embedded-opentype"), url("/webfonts/37FA53_1_0.woff2") format("woff2"), url("/webfonts/37FA53_1_0.woff") format("woff"), url("/webfonts/37FA53_1_0.ttf") format("truetype");
}
h1, h2, h3, h4 {
  font-family: Sailec-Bold;
  font-weight: normal;
  font-style: normal;
  line-height: 140%;
}

span, p, div {
  font-family: Sailec-Regular;
  font-weight: normal;
  font-style: normal;
  line-height: 140%;
}

.wrapper {
  padding: 100px 10%;
}

.text-container {
  max-width: 800px;
  margin: 0 auto;
}

.container-full {
  display: flex;
}

.container-full-green {
  display: flex;
  background-color: #233539;
}

.container-middle {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.half-width {
  max-width: 50%;
  position: relative;
}
.half-width.half-width-left {
  flex: 1;
  background: red;
}
.half-width.half-width-right {
  flex: 2;
}

.padding10 {
  padding: 10px 10px;
}

div {
  text-align: left;
}

h1, h2, h3, h4 {
  margin: 20px 20px;
}

h2 {
  font-size: 2em;
}

a, a:active, a:visited {
  color: #333;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

p {
  margin: 20px 20px;
  font-size: 1.2em;
  line-height: 1.6em;
}

.wf-text-white {
  color: #fff;
}

a.wf-links {
  color: #FF5A1E;
  text-decoration: none;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  transition: 0.3s ease all;
}
a.wf-links:hover {
  border-bottom: 1px solid #333;
  border-bottom-color: #FF5A1E;
}

.wf-underline {
  text-decoration: underline;
}

.wf-text-custom {
  color: #C8D8DB !important;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  h2 {
    font-size: 1.5em;
  }
  p {
    font-size: 1em;
  }
}