// .wf-form {
.wf-form-section {
  position: relative;
  margin: 20px auto;
  &.wf-form-section-inline {
    display: inline-block;
    width: 50%;
    padding-right: 20px;
    box-sizing: border-box;
  }
  &.wf-form-section-block {
    margin-top: 25px;
  }
  > label {
    // display: block;
    position: absolute;
    left: 0;
    color: #9098a9;
    font-weight: 500;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    /*
            &.input-focused {
                font-size: 16px;
                transform: translateY(-15px) scale(.85);
            }
            */
  }
  > input,
  textarea {
    background: transparent;
    border: none;
    width: 100%;
    color: #ffffff;
    padding-right: 20px;
    -webkit-appearance: none;
    background: none;
    box-sizing: border-box;
    border-radius: 0;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #fff;
    transition: all 0.15s ease;
    &:focus {
      border: 0;
      outline: none;
    }
  }
  // textarea {
  //     resize: none !important;
  // }
}
// SUBMIT SECTION
.wf-contact-submit {
  text-transform: uppercase;
  padding: 10px 15px;
  background: #ff5a1e;
  box-shadow: none;
  border: none;
  color: #fff;
  &::after {
    content: '→';
    margin-left: 3px;
    transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::after {
    margin-left: 6px;
  }
}

label {
  letter-spacing: 1px;
}
