$image-one: url('../../../resources/video-stills/OSK_04.jpg');
$image-two: url('../../../resources/video-stills/RACINNE_04.jpg');
$image-three: url('../../../resources/video-stills/POL_04.jpg');

.value-statement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .side-nav-container .side-nav-wrapper .side-nav {
        top: 35px;
    }
    .value-statement-image {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        min-height: 50vh;
        cursor: pointer;
        background-color: #000;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: background-image 0.5s ease-in-out;
        @media only screen and (min-width: $tablet) {
            height: 600px;
        }
        &.value-statement-image-0 {
            background-image: $image-one;
        }
        &.value-statement-image-4 {
            background-image: $image-three;
        }
        &.value-statement-image-7 {
            background-image: $image-two;
        }
        h3, h4 {
            color: #fff;
            margin: 0;
        }
        h3 {
            margin: 0 0 0 30px;
        }
        h4 {
            margin: 0 0 30px 30px;
            font-family: 'Sailec-Regular';
            font-size: 0.8em;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .value-statement-content {
        left: -50px;
        background-color: #fff;
        border-radius: 3px;
        > div {
            padding: 40px 40px;
            max-width: 600px;
        }
    }
}

.wf-preload-image {
    opacity: 0;
    z-index: -1;
    .one {
        background: $image-one;
    }
    .two {
        background: $image-two;
    }
    .three {
        background: $image-three;
    }
}

// Media Queries - Must refactor so it starts from mobile out
@media screen and (max-width:768px) {
    .value-statement-container {
        flex-direction: column-reverse;
        .side-nav-container {
            width: 35px;
            .side-nav-wrapper .side-nav{
                left: -42px;
            }
        }
        .value-statement-image {
            height: 500px;
        }
        .value-statement-content {
            left:0px;
            > div {
                padding: 150px 10%;
            }
        }
        .half-width {
            max-width: none;
            width: 100%;
        }
    }
}
@media screen and (max-width: 480px) {
    .value-statement-container .value-statement-content {
        > div {
            padding: 100px 10%;
        }
    }
}